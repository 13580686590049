<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import viewRosterShifts from "./components/viewRosterShifts.vue";
    import allVacantShiftsListing from "./components/allVacantShiftsListing.vue";
    import allScheduleShiftsListing from "./components/allScheduleShiftsListing.vue";
    import sidebar from "./components/sidebar.vue";


    export default {
        page: {
            title: "Shifts Roster",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
            viewRosterShifts,
            allVacantShiftsListing,
            allScheduleShiftsListing,
            sidebar,
        },
        data() {
            return {
                title: "Roster Shifts",
                isClick: false,
                items: [
                    {
                        text: "Roster Shifts",
                    },
                    {
                        text: "Shift Listing",
                        active: true,
                    },
                ],
                loading: false,
              
               currentTabComponent: 'RosterShifts',
                tabs: [
                        {
                            id:0,
                            desc: 'Roster Shifts',
                            status: 'RosterShifts',
                            icon: 'fa-file'
                        },
                        {
                            id:1,
                            desc: 'Vacant Shifts',
                            status: 'vacant_shifts',
                            icon: 'fa-file'
                        },
                        {
                            id:2,
                            desc: 'Scheduled Shifts',
                            status: 'schedule_shifts',
                            icon: 'fa-file'
                        },
                ],

                rosterShifts:[],
                fields: [                   
                    {
                        key: "name",
                        label: "Name",
                        sortable: true,
                    },
                    {
                        label: "From",
                        key: "from",
                        formatter:(key) => {
                            return this.DateWithMonthName(key)
                        },
                        sortable: true,
                    },
                    {
                        label: "To",
                        key: "to",
                        formatter:(key) => {
                            return this.DateWithMonthName(key)
                        },
                        sortable: true,
                    },
                
                    {
                        key: "shift_type",
                        label: "Type",
                        sortable: true,
                    },
                        "roles",

                    {
                        key: "location.name",
                        label: "Location",
                        sortable: true,
                    },
                    "selected_slots",
                    "vacant_slots",
                ],
                total_records: null,
                currentPage: 1,
                perPage: 10,
                sortDesc: false,
                table_data : {
                    draw: 0,
                    length: 10,
                    
                },
                extraFilters: {},
            };
        },

        mounted() {
            this.fetchShifts();
        },
        middleware: "authentication",
        methods: {
            fetchShifts(currentPage) {
                this.loading = true 
                this.table_data.page = currentPage;
                this.table_data.draw++;
                this.$axios.get('/shift/roster-shifts',{params: this.table_data})
                .then(response => {
                    this.rosterShifts = response.data.payload.data;
                    this.total_records = response.data.payload.total;
                    this.loading = false
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                })
                .finally(() => (this.loading = false));      
            },
            rolesToString(shift_roles) {
                let name_array = shift_roles.map((role) => {
                    return role.name;
                });
                let string = name_array.join(' , ').toString();

                return string;
            },
            
            RosterScheduleShifts(shift_timelines)
            {
                this.$refs.rosterShifts.shift_timelines = shift_timelines;
            },

            shiftsRoster(shift, empty_slot)
            {
                this.$axios
                .get("shift-roles", {
                params: { shift_id: shift.id, empty_slot: empty_slot },
                })
                .then((response) => {
                    this.$refs.rosterShifts.shift_roles = response.data.payload;
                })
                .catch(error => {
                this.handleErrorResponse(error.response, "error");
                })
            },

            listenFromSideBar(newData) {
                if (newData?.location) {
                    if(newData?.location == "All"){
                        this.table_data.location_id = null;
                        this.location = "All";
                    }else{
                        this.table_data.location_id = newData.location.id;
                        this.location = newData.location;
                    }
                }
                if (newData?.shift_name) {
                    if (newData?.shift_name == "All") {
                        this.table_data.name = null;
                    }else{
                        this.table_data.name = newData?.shift_name;
                    }
                }

                if (newData?.shift_type) {
                    if (newData?.shift_type == "All") {
                        this.table_data.shift_type = null;
                    }else{
                        this.table_data.shift_type = newData?.shift_type;
                    }
                }
                
                if (newData?.created_by) {
                    if(newData?.created_by == "All"){
                        this.table_data.created_by = null;
                    }else{
                        this.table_data.created_by = newData.created_by;
                    }
                }

                if (newData?.interval) {
                    if(newData?.interval == "All"){
                        this.table_data.interval = null;
                    }else{
                        this.table_data.interval = newData.interval;
                    }
                }

                if (newData?.area) {
                    this.table_data.area_id = newData?.area?.id;
                }

                if (newData?.endDate) {
                  this.table_data.from = newData.startDate;
                  this.table_data.to = newData.endDate;
                }
                
                if (newData?.role) {
                    this.table_data.area_role_id = newData?.role?.id;
                }

                if (newData?.unAssignedRoleShifts) {
                    if(newData?.unAssignedRoleShifts == "All"){
                        this.table_data.unAssignedRoleShifts = null;
                    }else{
                        this.table_data.unAssignedRoleShifts = newData.unAssignedRoleShifts;
                    }
                }

                if (newData?.clearFilters) {
                    this.table_data = {draw: 0, length: 10};
                    this.location = "All";
                }

                if (newData != null) {
                    if(this.currentTabComponent == 'RosterShifts')
                    {
                        this.fetchShifts(); 
                    }
                    if(this.currentTabComponent == 'vacant_shifts')
                    {
                        this.$refs.vacantShiftsRef.fetchVacantShifts(); 
                    }
                    if(this.currentTabComponent == 'schedule_shifts')
                    {
                        this.$refs.scheduleShiftsRef.fetchScheduleShifts(); 

                    }
                }
            },
            
        },

        computed: {
            rows() {
                return this.total_records;
            },
        },
        watch:{
            currentPage:{
                handler:function(currentPage,pre_currentPage){
                    this.fetchShifts(currentPage);
                }
            },
            currentTabComponent:{
                handler:function(newval , prevVal){
                    if(newval == 'RosterShifts')
                    {
                        this.fetchShifts();
                    }
                }
            }
        },

    };
</script>

<template>
    <Layout>
    <div id="section">
        <div id="place_content" class="row" >
            <div 
                class="col-xl-2 ps-0 position-relative transition_class"
                :class="{ fullWidth: isClick }" >
                <sidebar ref="sideBar" @filterData="listenFromSideBar" />
                <i class="fas position-absolute top-0 end-0 px-4 f-16 primaryColor pointer"
                    @click="isClick = !isClick"
                    :class="isClick == false ? 'fa-times' : 'fa-bars'" >
                </i>
            </div>

            <div  class="col-xl-10" :class="{ 'col-xl-12': isClick }" >
                <PageHeader :items="items" :title="title" />
                <div class="row">    
                    <div class="col-12 col-md">
                        <ul class="nav nav-pills" role="tablist">
                            <li class="nav-item" v-for="(tab,index) in tabs" :key="index">
                                <a class="nav-link text-dark d-flex align-items-center"
                                :class="{gradientColor: currentTabComponent === tab.status,
                                        'text-white': currentTabComponent === tab.status,}"
                                :id="tab.id"
                                @click="currentTabComponent = tab.status"
                                data-toggle="tab" :href="'#'+currentTabComponent" aria-controls="shift-listing" role="tab"
                                aria-selected="true">
                                    <span class="d-sm-block">{{ tab.desc }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-body under-tabs">
                        <div class="row" >
                            <div class="col-12">
                                
                                    <div class="tab-content" >
                                        <div v-if="currentTabComponent=='RosterShifts'">
                                            <div class="table-responsive mb-0">
                                                <b-table
                                                    table-class="table table-centered datatable mt-3 table-bordered"
                                                    thead-tr-class="bg-transparent"
                                                    :items="rosterShifts"
                                                    :fields="fields"
                                                    responsive="sm"
                                                    :per-page="0"
                                                    :current-page="currentPage"
                                                    :sort-desc.sync="sortDesc"
                                                    show-empty
                                                    :busy="loading"
                                                >
                                                <template #table-busy>
                                                    <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
                                                </template>
                                                <template v-slot:cell(roles)="data">
                                                    <template v-if="data.item.shift_roles.length > 0">
                                                    <p class="text-capitalize"> {{rolesToString(data.item.shift_roles)}}</p>
                                                    </template>
                                                    <template v-else> <p>N/A</p></template>
                                                </template>

                                                <template v-slot:cell(selected_slots)="data" >
                                                
                                                    <a  class="primaryColor"  v-b-modal.modal-xl  @click="RosterScheduleShifts(data.item.shift_timeline)" >View Selected Shifts</a>
                                                </template>

                                                <template v-slot:cell(vacant_slots)="data" >
                                                    <a class="primaryColor"   v-b-modal.modal-xl  @click="shiftsRoster(data.item , empty_slot = true)" >View Vacant Shifts</a>
                                                </template>
                                                </b-table>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="dataTables_paginate paging_simple_numbers float-end">
                                                    <ul class="pagination pagination-rounded">
                                                        <!-- pagination -->
                                                        <b-pagination
                                                        v-model="currentPage"
                                                        :total-rows="rows"
                                                        :per-page="perPage"
                                                        ></b-pagination>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <viewRosterShifts ref="rosterShifts"> </viewRosterShifts>
                                        </div>
                                        <allVacantShiftsListing  v-if="currentTabComponent=='vacant_shifts'" ref="vacantShiftsRef" :vacantPropFiltelrs="table_data" />
                                        <allScheduleShiftsListing  v-if="currentTabComponent=='schedule_shifts'" ref="scheduleShiftsRef" :schedulePropFiltelrs="table_data"/>
                                    </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Layout>
</template>
