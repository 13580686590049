<script>
import AlertWidget from "@/components/general/AlertWidget";
import $ from "jquery";

    export default {
        data() {
            return {
                shift_timelines: [],
                shift_roles: [],
                rosterModel:false,
                area_roles: [],
                userField: false,
            };
        },

        components:{
            AlertWidget
        },
        methods: {
          checkAreaRole(shift_roles)
            {
                let name_array = shift_roles.map((role) => {
                        return role.name;
                });
                let string = name_array.join(' ');
                return string
            },
            closeModal()
            {
                this.rosterModel = false;
                this.userField = false;
                this.shift_timelines = [];
                this.shift_roles = [];
            },
            getUserInfo(user)
            {
                this.userField = true;
                return user.email;
            },
            print(){
                const section = $("#section");
                const modalBody = $("#model-content").detach();
                const content = $("#place_content").detach();
                const modal = $("#modal-xl").detach();
                section.append(modalBody);
                window.print();
                section.empty();
                section.append(content);
                $("#modal-parent").append(modal);
                this.closeModal();
            }
        },

        middleware: "authentication",
    };
</script>

<template>
    <div class="modal-parent">
        <b-modal id="modal-xl"  scrollable v-model="rosterModel" content-class="shadow" size="xl" title="Shift Time Slots" title-class="text-black poppins font-18" hide-footer body-class="p-3" no-close-on-backdrop no-close-on-esc hide-header-close >
            <div id="modal-wrapper">
                <table class="table mb-0 table-bordered text-center" id="model-content">
                    <thead class="bg-light">
                        <tr>
                            <!-- <th class="f-14 fw-semiBold roboto text-dark" v-if="userField">Avatar</th> -->
                            <th class="f-14 fw-semiBold roboto text-dark" v-if="userField">User Email</th>
                            <th class="f-14 fw-semiBold roboto text-dark">From</th>
                            <th class="f-14 fw-semiBold roboto text-dark">To</th>
                            <!-- <th class="f-14 fw-semiBold roboto text-dark">Slot Type</th> -->
                            <th class="f-14 fw-semiBold roboto text-dark">Role</th>
                            <th class="f-14 fw-semiBold roboto text-dark" v-if="!userField">Remaining Slots</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="shift_timelines.length > 0">
                            <tr v-for="(shift_time, index) in shift_timelines" :key="index">
                                <td v-if="shift_time.employee" scope="row" class="f-14 roboto pColor"> {{getUserInfo(shift_time.employee.user)}}</td>
                                <td scope="row" class="f-14 roboto pColor">{{DateWithMonthName(shift_time.from)}}</td>
                                <td scope="row" class="f-14 roboto pColor">{{DateWithMonthName(shift_time.to)}}</td>
                                <td scope="row" class="f-14 roboto pColor text-capitalize">{{shift_time.shift_role.role.name}}</td>
                                <td scope="row" class="f-14 roboto pColor text-capitalize"  v-if="!userField">{{shift_time.shift_role.remaining_slots}}</td>
                            </tr>
                        </template>
                        <template v-else-if="shift_roles.length > 0">
                            <tr v-for="(shift_role, index) in shift_roles" :key="index">
                                <td scope="row" class="f-14 roboto pColor">{{DateWithMonthName(shift_role.from)}}</td>
                                <td scope="row" class="f-14 roboto pColor">{{DateWithMonthName(shift_role.to)}}</td>
                                <td scope="row" class="f-14 roboto pColor text-capitalize">{{shift_role.role.name}}</td>
                                <td scope="row" class="f-14 roboto pColor text-capitalize">{{shift_role.remaining_slots}}</td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td colspan="5">
                                <AlertWidget  class="mt-4" text="No Record Found"></AlertWidget>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="modal-footer-wrapper">
                <div class="text-end p-3 pe-0" id="model-footer">
                    <button type="button" class="btn btn-outline-pink mx-3" @click="closeModal()">Close</button>
                    <button type="button" class="btn btn-outline-pink" @click="print();" >Print</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
