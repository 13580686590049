<script>
import excelParser from "@/mixins/excel-parser.js";
import $ from "jquery";
    export default {
        props: {
            schedulePropFiltelrs : {
                type : Object,
                default: () => {
                    return {}
                }
            }
        },
        mixins:[excelParser],

        data() {
            return {
                fields: [ 
                    "avatar",
                    "user_name",
                    // "user_email",
                    "shift",

                    {
                        label: "From",
                        key: "from",
                        formatter:(key) => {
                            return this.DateWithMonthName(key)
                        },
                        sortable: true,
                    },
                    {
                        label: "To",
                        key: "to",
                        formatter:(key) => {
                            return this.DateWithMonthName(key)
                        },
                        sortable: true,
                    },
                    {
                        label: "Role",
                        key: "role.name",
                    },
                    "location"

                ],
                total_records: null,
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                sortDesc: false,
                table_data : {
                    draw: 0,
                    length: 10,
                    
                },
                scheduleArray : [],
                date_range: {
                    startDate: null,
                    endDate: null,
                },
                exportList: [],
                loading: false,
                per_page: ["10", "20", "50", "100"],
                length : 10,
            };
        },
        components: {
        },
        methods: {
            fetchScheduleShifts(currentPage , length =10 ,{callApi=false} = {})
            {
                this.loading = true;
                this.table_data.draw++;
                const filters = {
                    ...this.schedulePropFiltelrs,
                    page:currentPage, 
                    roster_type: 'schedule_shifts',
                }
                filters.length = length;
                this.$axios.get('/shift/all-shift-slots',{params: filters})
                .then(response => {
                    this.scheduleArray = response.data.payload.data;
                    this.total_records = response.data.payload.total;
                    this.loading = false     
                    // if(callApi)
                    // {
                    //     setTimeout(() => {
                    //         this.print();
                    //         this.fetchScheduleShifts();
                    //     }, 500);
                    // }          
                }).catch(error => {
                this.handleErrorResponse(error.response, "error");
                })
                .finally(() => (this.loading = false));
            },

            exportCSV()
            {
                const filters = {
                    ...this.schedulePropFiltelrs,
                    roster_type: 'schedule_shifts',
                }
                filters.length = this.total_records;
                this.$axios.get('/shift/all-shift-slots',{params: filters})
                .then(response => {
                    let scheduleShiftsExport= response.data.payload.data;
                    let buildData = scheduleShiftsExport.map((item) => {
                        return {
                            'Name' : this.getSafe(() => item.shift_timeline[0].employee.user.name),
                            'Email' : this.getSafe(() => item.shift_timeline[0].employee.user.email),
                            'Shift Name' : this.getSafe(() => item.shift.name),
                            'From' : this.getSafe(() => this.DateWithMonthName(item.from)),
                            'To' : this.getSafe(() => this.DateWithMonthName(item.to) ),
                            'Location' : this.getSafe(() => item.shift.location.name),
                            'Role/Position' : this.getSafe(() => item.role.name),
                        }
                    })
                    excelParser.methods.exportDataFromJSON(buildData , 'schedule-shifts' , null)
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                })
            },
            print(){
                const section = $("#section");
                const modalBody = $("#model-content").detach();
                const content = $("#place_content").detach();
                // const modal = $("#modal-xl").detach();
                section.append(modalBody);
                window.print();
                section.empty();
                section.append(content);
                $("#modal-parent").append(modalBody);
            },
            printAll()
            {
                this.length = this.total_records;
                this.fetchScheduleShifts(null , this.length , {callApi : true});
            }
        },
        mounted(){
            this.fetchScheduleShifts();
        },
        computed: {
            rows() {
                return this.total_records;
            },
        },
       
        watch:{
            currentPage:{
                handler:function(currentPage,pre_currentPage){
                    this.fetchScheduleShifts(currentPage , this.length);
                }
            },
            length: {
                handler: function (length, prev_length) {
                    if (length) {
                        this.perPage = length;
                        this.fetchScheduleShifts(this.currentPage, length);
                    }
                },
            },
        },

        middleware: "authentication",
    };
</script>

<template>
    <div class="">
        <div class="card-body pt-0">
            <div class="text-end" >
                <button class="btn gradientColor text-white d-inline-block text-end" @click="exportCSV();">
                    <i
                    class="uil uil-export font-size-18 align-middle me-1 "
                    ></i>
                    <span
                    class="d-xl-inline-block ms-1 fw-medium font-size-15"
                    >Export as CSV</span>
                </button>
                <!-- <b-dropdown
                class="d-inline-block text-end"
                variant="pink"
                right
                menu-class="dropdown-menu-left"
                >
                    <template v-slot:button-content>
                        <i
                        class="uil uil-export font-size-18 align-middle me-1 "
                        ></i>
                        <span
                        class="d-xl-inline-block ms-1 fw-medium font-size-15 arrow-down"
                        >Export</span>
                    </template>

                    <a class="dropdown-item" style="cursor: pointer;" @click="print();" >
                        <i
                        class="uil uil-print font-size-18 align-middle me-1 text-muted"
                        ></i>
                        <span class="align-middle">Print</span>
                    </a>
                     <a class="dropdown-item" style="cursor: pointer;" @click="printAll();" >
                        <i
                        class="uil uil-print font-size-18 align-middle me-1 text-muted"
                        ></i>
                        <span class="align-middle">Print All</span>
                    </a>
                    <a class="dropdown-item" style="cursor: pointer;" @click="exportCSV();">
                        <i
                        class="uil uil-clipboard-alt font-size-18 align-middle me-1 text-muted"
                        ></i>
                        <span class="align-middle" >Export as CSV</span>
                    </a>
                </b-dropdown> -->
            </div>
        <div id="modal-parent">
            <div class="col-sm-12 col-md-2">
                <div class="dataTables_length" id="DataTables_Table_0_length">
                <div class="d-flex align-items-center gap-2">
                    <span class="d-block"> Show </span>
                    <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    class="custom-select"
                    v-model="length"
                    >
                    <option
                        v-for="(item, index) in per_page"
                        :key="index"
                        :value="item"
                    >
                        {{ item }}
                    </option>
                    </select>
                    <span class="d-block"> entries </span>
                </div>
                </div>
            </div>
            
            <div id="model-content">
                <div class="table-responsive mb-0" >
                    <b-table 
                        table-class="table table-centered datatable mt-3 table-bordered"
                        thead-tr-class="bg-transparent"
                        :items="scheduleArray"
                        :fields="fields"
                        responsive="sm"
                        :per-page="0"
                        :current-page="currentPage"
                        :sort-desc.sync="sortDesc"
                        show-empty
                        :busy="loading"
                        disable-pagination
                    >
                        <template #table-busy>
                            <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
                        </template>
                        <template v-slot:cell(shift)="data" >
                            <p>{{getSafe(() => data.item.shift.name)}}</p>
                        </template>

                        <template v-slot:cell(area_role)="data">
                            <template >
                                <p class="text-capitalize">{{getSafe(() => data.item.area_roles.name)}}</p>
                            </template>
                        </template>

                        <template v-slot:cell(location)="data">
                            <template >
                                <p class="text-capitalize">{{getSafe(() => data.item.shift.location.name)}}</p>
                            </template>
                        </template>

                        <!-- <template v-slot:cell(user_email)="data" >
                            <p>{{data.item.shift_timeline[0].employee.user.email}}</p>
                        </template> -->

                        <template v-slot:cell(user_name)="data" >
                            <p>{{getSafe(() => data.item.shift_timeline[0].employee.user.name)}}</p>
                        </template>

                        <template v-slot:cell(avatar)="data" >
                            <img class="avatar-sm" :src="data.item.shift_timeline[0].employee.user.avatar_path">
                        </template>
                    
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded">
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            ></b-pagination>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    </div>
</template>